import React, { memo as Memo, useMemo, useEffect, useRef } from 'react';
import { gsap, TimelineLite } from "gsap";
import MotionPathPlugin from 'gsap/dist/MotionPathPlugin'

const ArrowLeftBtn = Memo(({ animate }) => {

    gsap.registerPlugin(MotionPathPlugin);

    //! Refs
    const arrLeftRef = useRef();
    const arrLeftRefPath = useRef();

    const tl = useMemo(() => new TimelineLite({}), [])

    useEffect(() => {
        tl.to(arrLeftRef.current, {
            duration: 0.8,
            repeat: -1,
            repeatDelay: 0,
            yoyo: true,
            ease: "power1.inOut",
            motionPath: {
                path: arrLeftRefPath.current,
                align: arrLeftRefPath.current,
                autoRotate: 0,
                alignOrigin: [0.5, 0.5]
            }
        })
    }, [])

    return (
        <svg className='svg-max' preserveAspectRatio="none" viewBox="0 0 250 63.7">
            <path ref={arrLeftRef} d="M250,61.4l-49.7,2.3l-0.5-11.8l30.9-1.4c-15.6-13.6-33.7-24.1-53.3-30.9C125.1,1.9,69.3,13.3,11.5,53.7
				l-4.8,3.4L0,47.4l4.8-3.4C81.1-9.2,143.1-4.6,181.7,8.7c20.5,7.1,39.5,18,55.9,32.2l-0.3-30.6l11.8-0.1L250,61.4z"/>
            <path ref={arrLeftRefPath} stroke="none" fill="none" d="M79.9,13.1c7.5-2.8,16.5-5.4,26.7-7c26.8-4.2,49.4,0.5,63.4,4.7" />
        </svg>
    )
})

export default ArrowLeftBtn;
