import React, { memo as Memo, useCallback, useMemo, Fragment, useRef } from 'react'
import CustomLink from '../CustomLink'
import ArrowBtnLink from '../SvgComponents/ArrowBtnLink'
import { TimelineMax, TimelineLite } from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'

//* HOCs
import withDataContext from '@context/consumerHOC/DataConsumer'
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'

//* Component
import Text from '../Text';
import ArrowLeftBtn from '../SvgComponents/ArrowLeftBtn'

//* Helpers
import stringToLetters from '@helpers/stringToLetters';
import isMobile from '@helpers/isMobile'

//* Style 
import style from './style.module.scss'

const Button = Memo(({ selectedLang, text, otherLink, scrollLink, teamFormBtn, children, className, link, active, turnAnimationParams, customClass, otherLinkProps, ...props }) => {

    //! Refs
    const ref = useRef()

    //! Animation
    const handleHover = useCallback((enter) => {
        if (!isMobile()) {
            let splitTxt = new SplitText('.load-more', { type: "chars,words" });
            let tl = new TimelineMax({ repeat: 0 });

            if (enter) {
                tl.staggerTo(splitTxt.chars, 0.15, { fontWeight: 700 }, 0.02)
            } else {
                tl.staggerTo(splitTxt.chars, 0.15, { fontWeight: 300 }, 0.02)
            }
        }
    }, [])

    const tl = useMemo(() => new TimelineLite({ paused: true }), [])

    const turnAnimation = useCallback((enter) => {
        if (enter) {

            if (turnAnimationParams) {
                tl.to(ref.current, 0.2, { rotation: -18 }).play()
            }
            else {
                tl.to(ref.current, 0.2, { rotation: 15 }).play()
            }

        } else {
            tl.reverse()
        }
    }, [])

    const letterifyText = useMemo(() => stringToLetters(text), [text])

    //! Button Type
    const btn = useMemo(() => {
        if (link) {
            return (
                <div className={`arrow-btn crCircle ${style.buttonCont}`}>
                    <span onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
                        <CustomLink url={link} className={`${style.button} ${props.type === 1 ? style.black : style.white} ${props.fontSize || 'h2'} font-poppins font-italic ${customClass}`} {...props}
                            content={<Fragment> <ArrowBtnLink animate={true} /> {letterifyText}</Fragment>} />
                    </span>
                </div>
            )
        }

        else if (otherLink) {
            return (
                <a href={props.as || typeof otherLink === 'object' ? otherLink.pathname : otherLink} className={`${style.otherLinkWrap} ${className || ''}`} {...otherLinkProps} >
                    <div className={style.otherLink} ref={ref} onMouseEnter={() => turnAnimation(true)} onMouseLeave={() => turnAnimation(false)}>
                        <Text className={`p-m font-anonymous font-bold font-italic`} text={text} />
                    </div>
                </a>
                // <CustomLink url={props.as || otherLink} className={`${style.otherLinkWrap} ${className || ''}`} {...otherLinkProps} >
                //     <div className={style.otherLink} ref={ref} onMouseEnter={() => turnAnimation(true)} onMouseLeave={() => turnAnimation(false)}>
                //         <Text className={`p-m font-anonymous font-bold font-italic`} text={text} />
                //     </div>
                // </CustomLink>
            )
        }

        else if (scrollLink) {
            return (
                <div className={`${style.otherLinkWrap} ${className ? className : ''}`} onClick={props.onClick}>
                    <div className={style.otherLink} ref={ref} onMouseEnter={() => turnAnimation(true)} onMouseLeave={() => turnAnimation(false)}>
                        <Text className={`p-m font-anonymous font-bold font-italic`} text={text} />
                    </div>
                </div>
            )
        }

        else if (teamFormBtn) {
            return (
                <div className={`${style.teemformBtn}`}>
                    <div onClick={props.onClick}>
                        <ArrowLeftBtn animate={true} />
                    </div>
                    <button
                        className={`${style.teemButtonForm} p-m crCircle font-poppins font-medium}`}
                        onClick={props.onClick}
                        {...props}
                    >
                        {text || children}
                    </button>
                </div>
            )
        }

        return (
            <div className={style.buttonFormCont}>
                <button
                    className={`${style.buttonForm} p-m  font-poppins font-medium} crCircle`}
                    onClick={props.onClick}
                    {...props}
                >
                    {text || children}
                </button>
            </div>
        )
    }, [className, text, children, otherLink, link, customClass, props])

    return btn
})


export default withLanguageContext(withDataContext(Button), ['selectedLang'])