import React, { Fragment, memo as Memo } from 'react'

//* HOCs
import widthLanguageContext from '@context/consumerHOC/LanguageConsumer'

//* Helpers
import breakString from '@helpers/breakString'

//* Components
import PortfolioList from '@components/cfModules/PortfolioList'
import MinTitle from '../MinTitle'
import Button from '../Button'
    ;

const ServicesPortfolioList = Memo(({ active, title, serviceType, portfolioFull, ...props }) => {
    return (
        <Fragment>
            <MinTitle active={active} title={breakString(title)} />

            <PortfolioList portfolioFull={portfolioFull} maxCount={6} serviceType={serviceType} />

            <Button link={props.link} customClass='load-more' text={props.translate("allWorksHere")} type={1} />
        </Fragment>

    )
})

export default widthLanguageContext(ServicesPortfolioList, ['translate']);