import React, { memo as Memo, useMemo, useState, useCallback, Fragment, useRef, useEffect } from 'react'
import _ from 'lodash'
import Moment from 'react-moment';
import 'moment-timezone';
import { TweenLite } from 'gsap'

//* HOCs
import withDataContext from '@context/consumerHOC/DataConsumer'
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'

//* Config
import configJson from 'config.json'

//* Components
import CustomLink from '@components/global/CustomLink'
import HTMLContainer from '@components/global/HTMLContainer'
import Image from '@components/global/Image'
import Text from '@components/global/Text'
import Filters from '@components/global/Filter'
import AnimationWrap from '@components/global/AnimationWrap'

//* Style 
import style from './style.module.scss'

const PortfolioList = Memo(({ cf, selectedLang, maxCount, serviceType, translate, portfolioFull }) => {
    //! States
    const [portfolioItems, setPortfolioItems] = useState()
    const [hoverKey, setHoverKey] = useState()
    const [filterSelect, setFilterSelect] = useState("all")

    //! Refs
    const ref = useRef([])

    //! Handle Active Section Animation
    const Anim = useCallback((x) => {
        if (JSON.stringify(x) !== JSON.stringify(portfolioItems)) {
            setPortfolioItems(x)
        }
    }, [portfolioItems])

    const changeFilterType = (currentTarget) => {
        setFilterSelect(currentTarget)
    }

    let counter = 0;

    //! Data
    const config = useMemo(() => configJson.routes.default.portfolio, [])
    const data = useMemo(() => cf?.[config.cfContentType]?.[selectedLang], [cf, selectedLang, config.cfContentType])

    const filtersItem = useMemo(() => {
        let arr = []
        if (data) {
            const itemsArr = Object.values(data.items)
            for (let i = 0; i < itemsArr.length; i++) {
                const item = itemsArr[i]
                arr.push(item.fields.workType)
            }
        }
        return arr
    }, [data])

    const animate = useCallback((ref) => {
        if (ref) {
            TweenLite.to(ref, 0.3, { opacity: 1 })
        }
    }, [])

    //! List Single Item
    const singleItem = useCallback((item, i, active) => {
        return (
            <div className={` ${style.portfolioItem}`} key={i} onMouseEnter={() => setHoverKey(i)} onMouseLeave={setHoverKey}>
                <CustomLink
                    className={`crLight`}
                    url={`${config.path}/${item.fields.slug}`}
                >
                    <Fragment>
                        <div className={`${style.image_video_cont} ${hoverKey === i ? style.image_active : ''}`} ref={el => ref.current[i] = el} >
                            {
                                hoverKey === i &&
                                (
                                    counter === 2 || counter === 5 ?
                                        <video ref={videoRef => animate(videoRef)} src={item.fields.shortVideo.fields.file.url} loop={true} autoPlay={true} muted />
                                        :
                                        <video ref={videoRef => animate(videoRef)} src={item.fields.shortVideoLandscape.fields.file.url} loop={true} autoPlay={true} muted />
                                )
                            }
                            {
                                counter === 2 || counter === 5 ?
                                    <Image src={item.fields.smallImage.fields.file.url} alt={item.fields.smallImage.fields.title} className={`p-image ${style.image_video}`} />
                                    :
                                    <Image src={item.fields.smallImageLandscape.fields.file.url} alt={item.fields.smallImageLandscape.fields.title} className={`p-image ${style.image_video}`} />
                            }
                        </div>

                        <Text tag={"h3"} className={`h5 font-poppins font-medium ${style.title}`}>
                            {item.fields.title}
                        </Text>

                        <Text className={`p-l font-poppins font-regular ${style.description}`}>
                            {translate(item.fields.workType)}
                        </Text>

                        <Text className={`p-l font-poppins font-italic ${style.createdDate}`}>
                            <Moment format="YYYY">
                                {item.fields.createdAt}
                            </Moment>
                        </Text>
                    </Fragment>
                </CustomLink>
            </div>
        )
    }, [hoverKey, portfolioItems, config.path, counter, filterSelect])

    //! Items
    const items = useMemo(() => {
        let arr = []

        if (data && portfolioItems) {
            let elCount = 0
            const itemsArr = Object.values(data.items),
                count = itemsArr.length
            for (let i = 0; i < count; i++) {
                if ((serviceType === itemsArr[i].fields.serviceType) || (!serviceType && (filterSelect === "all" || filterSelect === itemsArr[i].fields.workType))) {
                    const item = itemsArr[i]
                    if (maxCount) {
                        counter < 6 ? counter++ : counter = 1
                        elCount++;

                        if (elCount > maxCount) break

                        arr.push(singleItem(item, i, portfolioItems[`sec${[i + 1]}`]))

                    }
                    else {
                        counter < 6 ? counter++ : counter = 1

                        arr.push(singleItem(item, i, portfolioItems[`sec${[i + 1]}`]))
                    }
                }
            }
        }
        return arr
    }, [data, config.path, hoverKey, portfolioItems, maxCount, counter, filterSelect, selectedLang, serviceType])


    return (
        <AnimationWrap state={Anim} portfolioType={true}>
            <div className="section-item">
                {!maxCount ? <Filters parentCallback={changeFilterType} data={filtersItem} /> : ""}
                <HTMLContainer>
                    <div className={`${style.portfolioList} ${portfolioFull ? style.full : ''}`} >
                        {items}
                    </div>
                </HTMLContainer>
            </div>
        </AnimationWrap>
    )
})

export default withLanguageContext(withDataContext(PortfolioList, ['cf']), ['selectedLang', 'translate'])