import React, { memo as Memo } from 'react';
import NextImage from 'next/image'

import style from './style.module.scss';


const Image = Memo(({ src, alt, className }) => {
    const image = typeof src === 'string' ? src.replace('//images', 'https://images') : src;

    return (
        <div className={`${style.imageCont} ${className || ''}`}>
            <div className={style.image}>
                <NextImage
                    fill
                    sizes="(min-width: 320px) 100%"
                    src={image}
                    alt={alt}
                    draggable="false"
                    priority={true}
                />
            </div>
        </div>
    );
})

export default Image