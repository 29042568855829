import { useState, useEffect, useCallback } from 'react'

export function useActiveSection(containerRef, position = 50, screenOffset) {
    const [isActive, setIsActive] = useState(null)

    const onScroll = useCallback(() => {
        if (containerRef?.current) {
            const contTop = containerRef.current.getBoundingClientRect().top
            const contBottom = containerRef.current.getBoundingClientRect().bottom

            if (screenOffset) {
                setIsActive(contTop <= window.innerHeight * (position / 100) && contBottom >= 0)
            } else {
                setIsActive(contTop <= window.innerHeight * (position / 100))
            }
        }
        // else {
        //     console.error(`containerRef undefined in useActiveSection`)
        // }
    }, [containerRef])

    useEffect(() => {
        if (typeof window !== undefined) {
            onScroll()
            document.addEventListener('scroll', onScroll)
        }

        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])

    return isActive
}
