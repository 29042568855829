import React, { memo as Memo, useRef, useEffect } from 'react'
import { gsap } from 'gsap'

//* Helpers
import Text from '../Text'

//*style 
import style from './style.module.scss'

const MinTitle = Memo(props => {

    //! Refs
    let ref = useRef()

    //! Animation
    const tl = gsap.timeline()

    useEffect(() => {
        let arr = [ref.current.children]
        if (props.active) {
            tl.staggerTo(
                [arr], 0, {
                opacity: 1
            }, 0.2)
                .to(
                    [ref.current.children[1]], 0, {
                    delay: 0.3,
                    css: { fontStyle: 'italic' }
                })
        }
        else {
            tl.staggerTo(
                [arr], 0, {
                opacity: 0
            }, 0)
                .to(
                    [ref.current.children[1]], 0, {
                    css: { fontStyle: 'normal' }
                })
        }
    }, [props.active])

    return (
        <Text tag={props.tag || "h2"} className={`h1 font-poppins font-semi-bold ${style.title} ${props.className ? props.className : ''} `} ref={ref}>
            {props.title}
        </Text>
    )
})

export default MinTitle
