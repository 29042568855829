import React, { memo as Memo, useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { gsap } from 'gsap'
import 'moment-timezone';

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'


const AnimationWrap = Memo(({ portfolioType, winHeight, state, children }) => {
    //! States
    const [secObj, setSecObj] = useState({})

    //! Animation
    const tl = useMemo(() => gsap.timeline({}), [])

    //! Refs
    const revealRefs = useRef([]);

    const addToRefs = useCallback(el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    }, [])

    const sectionAnimation = useCallback(() => {
        let newObj = {}
        let isChange = false

        let cont = revealRefs.current[0].childNodes[0].childNodes[1]?.childNodes[0].childNodes || revealRefs.current[0].childNodes[0].childNodes[0]?.childNodes[0].childNodes

        if (revealRefs.current && !portfolioType) {
            cont = revealRefs.current[0].childNodes

            for (let i = 0; i < cont.length; i++) {
                const el = cont[i].getBoundingClientRect()
                const secIsActive = el.top < winHeight
                const elKey = i + 1
                newObj = { ...newObj, [`sec${elKey}`]: secIsActive }

                if ((secObj[`sec${elKey}`] && !newObj[`sec${elKey}`]) || !secObj[`sec${elKey}`] && newObj[`sec${elKey}`]) {
                    isChange = true
                }
            }
        }
        else {
            for (let i = 0; i < cont.length; i++) {
                const el = cont[i].childNodes[0].getBoundingClientRect()
                const secIsActive = el.top < winHeight
                const elKey = i + 1
                newObj = { ...newObj, [`sec${elKey}`]: secIsActive }

                if ((secObj[`sec${elKey}`] && !newObj[`sec${elKey}`]) || (!secObj[`sec${elKey}`] && newObj[`sec${elKey}`])) {
                    isChange = true
                }
            }
        }
        isChange && setSecObj(newObj)
        state(newObj)
    }, [revealRefs, secObj, tl, winHeight, portfolioType])

    useEffect(() => {
        window.scrollTo({ y: 1 })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnimation)

        return () => {
            window.removeEventListener('scroll', sectionAnimation)
        }
    }, [sectionAnimation])

    return (
        <div ref={addToRefs}>
            {children}
        </div>
    )
})

export default withUIContext(AnimationWrap, ['winHeight'])