import { memo as Memo, useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { gsap } from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/css';

//* Components
import Text from '@components/global/Text'
import HTMLContainer from '@components/global/HTMLContainer'
import Button from '@components/global/Button'
import Translate from '@components/global/Translate'
import CustomLink from '@components/global/CustomLink'

//* Helpers
import breakString from '@helpers/breakString'
import { useActiveSection } from '@helpers/_useActiveSection'

//* Style 
import style from './style.module.scss'

const Testimonials = Memo(({ title, items, dark }) => {
    //! Refs
    const sectionRef = useRef()
    const sliderParentContRef = useRef()
    const titleRef = useRef()
    const sliderRef = useRef()

    //! States
    const [activeSlide, setActiveSlide] = useState(0)
    const active = useActiveSection(sectionRef)

    //! Animation
    const tl = useMemo(() => gsap.timeline({ delay: 0.4 }), [])

    //! Check Animation 
    useEffect(() => {
        if (active) {
            tl.staggerTo(
                [titleRef.current.children, sliderParentContRef.current.children], 0, {
                opacity: 1
            }, 0.2)
                .to(
                    [titleRef.current.children[1]], 0, {
                    delay: 0.4,
                    css: { fontStyle: 'italic', fontWeight: 300 }
                }, 0.2)
        }
        else {
            tl.staggerTo(
                [titleRef.current.children, , sliderParentContRef.current.children], 0, {
                opacity: 0
            }, 0)
                .to(
                    [titleRef.current.children[1]], 0, {
                    css: { fontStyle: 'normal', fontWeight: "400" }
                }, 0)
        }
    }, [active])

    //! Change Slide
    const changeSlide = useCallback((key) => {
        setActiveSlide(key)
        sliderRef.current.slideTo(key)
    }, [])

    return (
        <div ref={sectionRef} className={`${style.testimonialsContainer} ${dark ? style.darkContainer : ''}`}>
            <HTMLContainer >
                <Text ref={titleRef} tag={"h2"} className={`h1 font-poppins font-semi-bold ${style.title}`} children={breakString(title)} />

                <div ref={sliderParentContRef} className={style.slideParentCont}>
                    <Swiper
                        className={style.slideContainer}
                        slidesPerView={1}
                        spaceBetween={40}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        onSwiper={swiper => sliderRef.current = swiper}
                        onSlideChange={(e) => setActiveSlide(e.activeIndex)}
                    >
                        {items.map((i, k) => {
                            return (
                                <SwiperSlide key={k} className={style.slide}>
                                    <div className={style.slideInner}>
                                        <i className={`cs-quote ${style.slideSymbol}`} />

                                        <Text className={`h4 font-poppins ${style.slideText} `} children={i.text} />

                                        <div className={style.slideInfoCont}>

                                            <Text className={`h6 font-poppins font-semi-bold ${style.slideInfo}`}>
                                                {`${i.name} ${i.position}, `} {i.company_url ? <CustomLink url={i.company_url} target="_blank" className="font-underline ">{i.company_name}</CustomLink> : `${i.company_name}`}
                                            </Text>

                                            {i.clutch_url && (
                                                <div className={style.slideButtonCont}>
                                                    <Button
                                                        otherLinkProps={{ target: '_blank' }}
                                                        className={style.slideButton}
                                                        otherLink={{
                                                            pathname: i.clutch_url,
                                                        }}
                                                        text={<Translate val={"viewOnClutch"} />}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <div className={style.pagination}>
                        {items.map((i, k) => {
                            return (
                                <span
                                    key={k}
                                    className={`${style.paginationItem} ${k === activeSlide ? style.paginationItemActive : ''}`}
                                    onClick={() => changeSlide(k)}
                                />
                            )
                        })}
                    </div>
                </div>
            </HTMLContainer >
        </div>
    )
})

export default Testimonials