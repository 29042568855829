import React, { memo as Memo, useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { gsap } from 'gsap'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'
import Text from '../Text'

//* Style 
import style from './style.module.scss'

const Filters = Memo(({ data, parentCallback, ...props }) => {
    //! States
    const [activeIndex, setActiveIndex] = useState(0)

    //! Refs
    let ref = useRef([])
    const filterRef = useRef([])
    const tl = useMemo(() => gsap.timeline({ pause: true }), [])
    const tl2 = useMemo(() => gsap.timeline({ pause: true }), [])
    const tl3 = useMemo(() => gsap.timeline({ pause: true }), [])

    const handelActiveClass = (x) => {
        setActiveIndex(x)
    }

    useEffect(() => {
        tl2.to(filterRef.current, 0, {
            opacity: 1,
            delay: 1.7
        }, 0.2)
    }, [props.winWidth]);

    const singleItemType = useCallback((itemType, i) => {
        return (
            <div ref={el => filterRef.current[i] = el} className={`${style.filtersItem} ${i === activeIndex ? style.active : ""}`} key={i} onClick={() => { parentCallback(itemType); handelActiveClass(i) }}>
                <Text className='p-m font-poppins font-medium'>
                    <span className={`${style.textVisible} p-m font-poppins font-medium`} ref={el => ref.current[i] = el}>{props.translate(itemType)}</span>
                    <span className={style.textHide}>{props.translate(itemType)}</span>
                </Text>
            </div>
        )
    }, [parentCallback])


    useEffect(() => {
        tl.staggerTo(filterRef.current, 0, { opacity: 1, delay: 1.7 }, 0.2)
    }, [props.winWidth]);

    //! Items filter
    const filtersItem = useMemo(() => {
        let includesResult = ['all']
        let result = []
        for (let str of data) {
            if (!includesResult.includes(str)) {
                includesResult.push(str);
            }
        }
        for (let i = 0; i < includesResult.length; i++) {
            result.push(singleItemType(includesResult[i], i));
        }
        return result
    }, [data, handelActiveClass])

    return (
        <div className={style.filterCont}>
            <div className={style.filterScrollWrap}>
                <div className={style.filterWrap}>
                    {filtersItem}
                </div>
            </div>
        </div>
    )
})

export default withLanguageContext(withUIContext(Filters, ['winWidth', 'screenSizes']), ['translate'])