const stringToLetters = (text) => {
    let wordLength = text.length;
    let arr = [];

    for (let i = 0; i < wordLength; i++) {
        arr.push(text.charAt(i));
    }

    return (
        <span className='word'>
            {arr.map((i, k) => {
                return (
                    <span className='letter' key={k}>{i}</span>
                )
            })}
        </span>
    )
}

export default stringToLetters;